import React, { lazy, Suspense } from 'react'
import { ButtonLink } from '../components/Button'
import config from '../../data/SiteConfig'

const Layout = lazy(() => import('../layout'))

const pageTitle = `Page not found – ${config.siteTitle}`

const ErrorPage = ({ location }) => (
  <Suspense fallback={<span />}>
    <Layout title={pageTitle} href={location.href}>
      <div className="container">
        <h1>404, It&apos;s lockdown and you&apos;re outside</h1>
        <h2>You&apos;re lost it seems, Go home, it&apos;s not safe outside</h2>
        <h3>Stay Home🏡, Stay Safe 😊</h3>

        <ButtonLink title="ashmeetsehgal.com HomePage" simpleLink className="button" href="/">
          Go home 🚶
        </ButtonLink>
        <h2>On a serious note:</h2>
        <p>
          I&apos;m afraid you&apos;ve found a page that doesn&apos;t exist on{' '}
          <a href="/"> ashmeetsehgal.com</a>. That can happen when you follow a link to something
          that has since been deleted. Or the link was incorrect to begin with. Sorry about that.
          We&apos;ve logged the error for review, in case it&apos;s our fault.
        </p>
      </div>
    </Layout>
  </Suspense>
)
export default ErrorPage
